<template>
  <div style="margin-top: 20px;margin-left: 20px;">
    <el-empty v-if="!loginCheck" description="暂无数据"></el-empty>
    <div v-if="loginCheck">
      <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="name"
            label="测试名称">
        </el-table-column>
        <el-table-column
            label="正确率">
          <template slot-scope="scope">
            <span>{{ scope.row.correctRate * 100 }}%</span>
          </template>
        </el-table-column>
        <el-table-column
            prop="duration"
            label="做题时长">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="details(scope.row)" type="text" size="small">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex;justify-content: center;margin-top: 10px;">
        <el-pagination
            layout="prev, pager, next"
            @current-change="jumpPage"
            :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 弹出层 -->
    <div>
      <!-- 详情 -->
      <div>
        <el-dialog title="详情" :visible.sync="dialogTableVisible">
          <el-descriptions  direction="vertical" :column="4" border>
            <el-descriptions-item label="测试名称">{{ gridData.name }}</el-descriptions-item>
            <el-descriptions-item label="成功率">{{ gridData.correctRate * 100 }}%</el-descriptions-item>
            <el-descriptions-item label="测试时长">{{ gridData.duration }}</el-descriptions-item>
            <el-descriptions-item label="测试时间">{{ gridData.updateTime }}</el-descriptions-item>
          </el-descriptions>
          <div style="margin-top: 10px;">
            <el-card shadow="never">
              <div v-for="(it, index) in topicList" :key="index" :style="{marginTop:(index !== 0 ? '30px': '')}">
                {{ index + 1 }}.
                <span v-html="it.topic.title"></span>
                <span v-if="it.topic.topicType===1">(判断题)</span>
                <span v-if="it.topic.topicType===2">(单选题)</span>
                <span v-if="it.topic.topicType===3">(填空题)</span>
                <span v-if="it.topic.topicType===4">(应用题)</span>
                <span v-if="it.topic.topicType===5">(多选题)</span>
                <el-tag type="danger" v-if="it.state===2" style="margin-left: 20px;">错误</el-tag>
                <div style="color: #67c23a;margin-top: 10px;" v-if="it.state===1">
                  我选择的是：{{it.optionsTag}}
                </div>
                <div style="color: red;margin-top: 10px;" v-if="it.state===2">
                  我选择的是：{{it.optionsTag}}
                </div>
                <span v-for="daan in gridData.contentList" :key="daan" v-if="daan.topicId===it.id">{{daan}}</span>
                <!-- eslint-disable -->
                <span v-if="content.topicId === it.id" v-for="(content, index3) in gridData.contentList" :key="index3" >
                  <span v-for="(itcc, index4) in it.optionsList" :key="index4" v-if="itcc.id===content.optionsId">{{ itcc.content }}</span>
                </span>
                <el-alert
                    v-for="(itc, index2) in it.optionsList"
                    :key="index2"
                    style="margin-top: 10px;"
                    :title="itc.content"
                    :type="itc.answer === 1?'success':'info'"
                    :closable="false">
                </el-alert>

                <div style="margin-top: 20px;" v-html="it.topic.parse"></div>
                <el-divider></el-divider>
              </div>

            </el-card>
          </div>
        </el-dialog>
      </div>

    </div>
  </div>
</template>

<script>
import token from "@/api/token"
import interview from "@/api/interview"
export default {
  name: "TestRecord",
  data() {
    return {
      loginCheck: false,
      queryForm: {
        pageNum: 1,
        pageSize: 10
      },
      tableData: [],
      total: 0,
      dialogTableVisible: false,
      gridData: {},
      topicList: [],
      loading: false
    }
  },
  created() {
    if (token.getToken()) {
      this.loginCheck = true
      this.answerRecordPage()
    } else {
      this.$message({
        message: '请登录后，在进行操作',
        type: 'warning'
      })
    }
  },
  methods: {
    /**
     * 查询测验记录
     */
    answerRecordPage() {
      this.loading = true
      interview.answerRecordPage(this.queryForm).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
        this.loading = false
      })
    },
    /**
     * 页面跳转
     * @constructor
     */
    jumpPage(pageNum){
      this.queryForm.pageNum = pageNum
      this.answerRecordPage()
    },
    /**
     * 详情
     */
    details(row) {
      this.dialogTableVisible = true
      interview.answerRecordDetails({
        id: row.id
      }).then(res => {
        this.gridData = res.data
        this.topicList = JSON.parse(this.gridData.content)
        console.log(JSON.stringify())
          let list = []
          var sum = 0;
          for (var i = 0; i < list.length; i++) {
              sum += list[i].wgt
          }
          console.log(JSON.stringify())

      })
    }
  }
}
</script>

<style scoped>

</style>
